import axios from 'axios';
import {API_BASE_URL} from 'configs/AppConfig';
import {signOutSuccess} from 'store/slices/authSlice';
import store from '../store';
import {AUTH_TOKEN} from 'constants/AuthConstant';
import {notification} from 'antd';
import Storage from "../utils/storage";

const unauthorizedCode = [401, 403]

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000
})

// Config
const TOKEN_PAYLOAD_KEY = 'Authorization'

// API Request interceptor
service.interceptors.request.use(config => {
    const token = Storage.getItem(AUTH_TOKEN);

    if (token) {
        config.headers[TOKEN_PAYLOAD_KEY] = `Token ${token}`
    }

    return config
}, error => {
    // Do something with request error here
    notification.error({
        message: 'Error'
    })
    Promise.reject(error)
})

// API response interceptor
service.interceptors.response.use(
    (response) => {
        return response.data
    },
    (error) => {

        let notificationParam = {
            message: null
        }

        // error object won't have request param if request is cancelled manually using cancelToken
        if(!error.request) {
            return Promise.reject(error);
        }

        const requestUrlObject = new URL(error.request.responseURL);

        // Remove token and redirect
        if (
            unauthorizedCode.includes(error?.response?.status) &&
            requestUrlObject.pathname !== '/common/reset-pass/' &&
            requestUrlObject.pathname !== '/common/reset-pass-verify/'
        ) {
            notificationParam.message = 'Session expired'
            notificationParam.description = 'Please login again'
            Storage.removeItem(AUTH_TOKEN)

            store.dispatch(signOutSuccess())
        }

        if (error.response.status === 404) {
            notificationParam.message = 'Not Found'
        }

        /*if (error.response.status === 500) {
            notificationParam.message = 'Internal Server Error'
        }*/

        if (error.response.status === 508) {
            notificationParam.message = 'Time Out'
        }

        if (notificationParam.message) {
            notification.error(notificationParam)
        }

        return Promise.reject(error);
    });

export default service