import fetch from 'auth/FetchInterceptor'
import {serialize} from "../utils/formHelper";

const RequestService = {};

RequestService.get = function (url, cancelToken = null) {
    return fetch.get(url, cancelToken ? { cancelToken } : {});
};

RequestService.post = function (url, data, sendAsFormData = false) {
    const requestData = sendAsFormData ? serialize(data) : data;

    const options = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': sendAsFormData ? 'multipart/form-data' : 'application/json'
        }
    }

    return fetch.post(url, requestData, sendAsFormData ? options : {})
};

RequestService.put = function (url, data, sendAsFormData = false) {
    const requestData = sendAsFormData ? serialize(data) : data;

    const options = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': sendAsFormData ? 'multipart/form-data' : 'application/json'
        }
    }

    return fetch.put(url, requestData, sendAsFormData ? options : {})
}

export default RequestService;
